import React from 'react';
import Nav from "../nav/Nav";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import './breadcrumb.scss';

export default function MainLayout({children, pageContext}) {
    const { breadcrumb: { crumbs } } = pageContext
    
    return <>
        <Nav/>
        <Breadcrumb crumbs={crumbs} />
        <div>{children}</div>
    </>
}