import React from "react"
import { graphql } from "gatsby"
import '../styles.scss';
import './markdownTemplate.scss';
import MarkdownLayout from "../components/layouts/MarkdownLayout";

export default function Template({
                                     data, pageContext
                                 }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    
    return (
        <div>              
            <MarkdownLayout pageContext={pageContext}>
                <div className="blog-post-container">                    
                    <div className="blog-post">
                        <h2>{frontmatter.title}</h2>
                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>
                </div>
            </MarkdownLayout>
        </div>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`