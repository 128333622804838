import React from 'react';
import '../../markdown.css';
import MainLayout from "./MainLayout";
import './MarkdownLayout.scss';

export default function MarkdownLayout({children, pageContext}) {
    return <>
        <MainLayout pageContext={pageContext}>
            <div className='markdown-container'>
                {children}
            </div>
        </MainLayout>
    </>
}