import React, {useState} from 'react'
import {AppBar, Tabs, Tab, IconButton, Typography, Button, Toolbar, Link, Menu, MenuItem} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menu: {
        
    },
    title: {
        flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
    link: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',  
        color: '#fff',
        visited: {
            color: '#fff'
        }
    }
}));

export default function Nav() {
    const classes = useStyles();
    const [anchorElement, setAnchorElement] = useState();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const open = Boolean(anchorElement);
    
    const handleMenu = (event: any) => {
        setAnchorElement(event.currentTarget);
        setMenuOpen(true);
    }

    const handleClose = () => {
        setMenuOpen(false);
    }

    
    const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              linkTitle
            }
          }
        }
      }
    }
  `);
    
    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton 
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup="true"
                        onClick={(e) => handleMenu(e)}
                        edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElement}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={menuOpen}
                        onClose={handleClose}
                        className={classes.menu}
                    >
                        { data.allMarkdownRemark.edges.map((edge: any) => edge.node.frontmatter.linkTitle ?
                        <Link key={edge.node.frontmatter.slug} href={edge.node.frontmatter.slug} color='primary' underline='none' className={classes.link}>
                            {edge.node.frontmatter.linkTitle}
                        </Link> : null
                        )}
                    </Menu>
                </Toolbar>                
            </AppBar>
        <div className={classes.offset} />
        </>
    );
}